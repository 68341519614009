import createReducer from "../helpers/createReducer";
import {
	GET_APP_LOGO_SUCCESS, GET_CURRENT_SCHOOL_SUCCESS,
	GET_GENDERS_SUCCESS, GET_MARKING_SYSTEMS_SUCCESS,
	GET_NATIONALITIES_SUCCESS,
	GET_PAYROLLS_SUCCESS, GET_QUARTER_LIST_SUCCESS,
	GET_ROOMS_SUCCESS, GET_STUDY_YEARS_SUCCESS,
	GET_SUBJECT_SUCCESS,
	RESET_IMAGE,
	SET_IMAGE, SET_STUDY_YEAR,
	UPLOAD_IMAGE_SUCCESS,
	SET_COPY_SCHEDULE_DETAILS,
	MODAL_VISIBLE,
	SET_REPLACEMENT_ITEM,
	RESET_REPLACEMENT_ITEM,
} from "../actions/sessionActions";

const defaultState = {
	image: null,
	nationality: [],
	payrolls: [],
	rooms: [],
	subject: [],
	gender: [],
	appLogo: null,
	studyYear: [],
	quarters: [],
	markingSystems: [],
	currentSchool:null,
	yearId:+localStorage.getItem('yearId')||null,
	copyScheduleDetails: {},
	modalVisible: false,
	replacementItem: {}
};
const reducers = {
	[SET_REPLACEMENT_ITEM](state, { data }) {
		return {
			...state,
			replacementItem: {...state.replacementItem, ...data},
		};
	},
	[RESET_REPLACEMENT_ITEM](state) {
		return {
			...state,
			replacementItem: {},
		};
	},
	[GET_NATIONALITIES_SUCCESS](state, { data }) {
		return {
			...state,
			nationality: data.results || [],
		};
	},
	[GET_PAYROLLS_SUCCESS](state, { data }) {
		return {
			...state,
			payrolls: data.results || [],
		};
	},
	[GET_ROOMS_SUCCESS](state, { data }) {
		return {
			...state,
			rooms: data.results || [],
		};
	},
	[GET_SUBJECT_SUCCESS](state, { data }) {
		return {
			...state,
			subject: data.results || [],
		};
	},
	[GET_GENDERS_SUCCESS](state, { data }) {
		return {
			...state,
			gender: data.choices || [],
		};
	},
	[GET_CURRENT_SCHOOL_SUCCESS](state, { data }) {
		return {
			...state,
			currentSchool: data||null,
		};
	},
	[UPLOAD_IMAGE_SUCCESS](state, { data }) {
		return {
			...state,
			image: data[0] || {},
		};
	},
	[GET_APP_LOGO_SUCCESS](state, { data }) {
		return {
			...state,
			appLogo: data,
		};
	},
	[GET_STUDY_YEARS_SUCCESS](state, { data }) {
		return {
			...state,
			studyYear: data?.results,
		};
	},
	[GET_QUARTER_LIST_SUCCESS](state, { data }) {
		return {
			...state,
			quarters: data?.results,
		};
	},
	[GET_MARKING_SYSTEMS_SUCCESS](state, { data }) {
		return {
			...state,
			markingSystems: data?.choices||[],
		};
	},
	[SET_STUDY_YEAR](state, { data }) {
		return {
			...state,
			yearId: data.id,
		};
	},
	[RESET_IMAGE](state) {
		return {
			...state,
			image: null,
		};
	},
	[SET_IMAGE](state, { image }) {
		return {
			...state,
			image,
		};
	},
	[SET_COPY_SCHEDULE_DETAILS](state, { data }) {
		return {
			...state,
			copyScheduleDetails: data
		}
	},
	[MODAL_VISIBLE](state, { _bool }) {
		return {
			...state,
			modalVisible: _bool
		}
	}
};
export default createReducer(defaultState, reducers);
