import qs from "qs";
import { pathParams } from "path-params";
import { identity, pickBy } from "lodash";

const Utils = {
	validateResponse(error, response) {
		if (error.status === 400) {
			return error;
		}
		if (error) {
			if (/network is offline/.test(error.message)) {
				return "No Internet Connection, Check your connection & restart the application\n";
			}

			if (error.timeout === 10000) {
				return 10000;
			}

			return `${error.message || "error"}\n`;
		}

		if (response.status !== 2 || response.status !== "success") {
			return `${response.message || "unknown error"}\n`;
		}

		return "";
	},
	parseJwt(token) {
		let base64Url = token.split(".")[1];
		let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		let jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map(function (c) {
					return (
						"%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
					);
				})
				.join("")
		);

		return JSON.parse(jsonPayload);
	},
	getFullNameLegacy(user) {
		return `${user.first_name || ""} ${user.last_name || ""} ${
			user.middle_name || ""
		} `;
	},
	getFullName(user) {
		return [user?.lastName, user?.firstName, user?.middleName]
			.filter(Boolean)
			.join(" ");
	},
	getShortName(user) {
		if (!user) {
			return "";
		}
		const firstName = user.firstName;
		// const middleName = user.middleName?.charAt(0);

		// const initial = [firstName, middleName].filter(Boolean).join(". ");
		const initial = [firstName].filter(Boolean).join(". ");

		return [user.lastName, initial].filter(Boolean).join(" ");
	},
	createUrl(path, options) {
		const query = qs.stringify(options.query);
		const url = [path, query].filter(Boolean).join("?");

		if (options.params) {
			return pathParams(url, options.params);
		}

		return url;
	},
	parseQuery(search) {
		return qs.parse(search.replace("?", "")) || {};
	},
	identityObject(object) {
		return pickBy(object, identity);
	},
	daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	},
	getChatDate(date) {
		const temp = date.toString() || "";
		const currentDate = new Date();
		if (temp?.slice(0, 10) == currentDate.toISOString().slice(0, 10)) {
			return temp?.slice(11, 16);
		}
		return temp.length > 5 ? temp?.slice(0, 10) : "";
	},
	phoneMask(e) {
		if(e){
			return e.replace('+998','')
				.replace(/\D/g, '')
				.replace(/^(\d)/, '($1')
				.replace(/^(\(\d{2})(\d)/, '$1)$2')
				.replace(/(\d{3})(\d{1,5})/, '$1-$2')
				.replace(/(-\d{2})(\d{1,5})/, '$1-$2')
				.replace(/(-\d{2})\d+?$/, '$1');
		}
		return "";
	},
	extractFirstTwoNames(fullName) {
		return (fullName || '').split(' ').splice(0, 2).join(' ');
	}

};
export default Utils;
