import createReducer from "../helpers/createReducer";
import {
  SET_ANNUAL_MATH_LIST_EDIT,
  ANNUAL_MATH_LIST_EDIT,
  SET_ANNUAL_MATH_LIST_ADD,
  ANNUAL_MATH_LIST_SAVE,
} from "../actionTypes/lessonActionTypes";

const defaultState = {
  annualMathList: [
    {
      id: 1,
      date: "03.09.2021",
      class_number: 9,
      theme: "Натуральные числа",
      comment: "Урок 1. Позиционная система счисления",
    },
    {
      id: 2,
      date: "05.09.2021",
      class_number: 7,
      theme: "Сложение и вычитание натуральных чисел",
      comment: "Урок 2. Вычитание натуральных чисел",
    },
    {
      id: 3,
      date: "07.09.2021",
      class_number: 8,
      theme: "Площади и объёмы",
      comment: "Урок 3. Точка и линия",
    },
    {
      id: 4,
      date: "09.09.2021",
      class_number: 7,
      theme: "Дробные числа",
      comment: "Урок 4. Обыкновенные дроби",
    },
    {
      id: 5,
      date: "12.09.2021",
      class_number: 6,
      theme: "Десятичные дроби. Сложение и вычитание десятичных дробей.",
      comment: "Урок 5. Решение задач на тему «Сложение и вычитание десятичных дробей»",
    },
    {
      id: 6,
      date: "15.09.2021",
      class_number: 6,
      theme: "Умножение и деление десятичных дробей",
      comment: "Урок 6. Умножение и деление на числа вида 10, 100, 0,1, 0,01",
    },
    {
      id: 7,
      date: "18.09.2021",
      class_number: 9,
      theme: "Инструменты для вычислений и измерений",
      comment: "Урок 7. Измерение углов. Транспортир",
    },
    {
      id: 8,
      date: "23.09.2021",
      class_number: 9,
      theme: "Основы комбинаторики",
      comment: "Основы комбинаторики",
    },
  ],
  annualMathListEditModal: false,
  annualMathListAddModal: false,
  item: {},
};

const reducers = {
  [SET_ANNUAL_MATH_LIST_EDIT](state, { item }) {
    return {
      ...state,
      item,
      annualMathListEditModal: !state.annualMathListEditModal,
    };
  },
  [ANNUAL_MATH_LIST_EDIT](state, { data }) {
    const res = state.annualMathList.map((annualMath) => {
      if (annualMath.id === state.item.id) {
        annualMath.date = data.date;
        annualMath.class_number = data.class_number;
        annualMath.theme = data.theme;
        annualMath.comment = data.comment;
      }
      return annualMath;
    });
    return {
      ...state,
      annualMathList: res,
      item: {},
      annualMathListEditModal: false,
    };
  },
  [SET_ANNUAL_MATH_LIST_ADD](state) {
    return {
      ...state,
      annualMathListAddModal: !state.annualMathListAddModal,
    };
  },
  [ANNUAL_MATH_LIST_SAVE](state, { data }) {
    const { annualMathList } = state;
    return {
      ...state,
      annualMathList: [...annualMathList, data],
    };
  },
};
export default createReducer(defaultState, reducers);
