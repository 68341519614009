export const SET_ADD_ROLE_MODAL_VISIBLE = "SET_ADD_ROLE_MODAL_VISIBLE";
export const DELETE_ROLE_MODAL_VISIBLE = "DELETE_ROLE_MODAL_VISIBLE";
export const SET_DELETING_ROLE = "SET_DELETING_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const SET_EDIT_MODE_ROLE = "SET_EDIT_MODE_ROLE";
export const SET_EDIT_ROLE = "SET_EDIT_ROLE";
export const GET_ROLE_LIST_START = "GET_ROLE_LIST_START";
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS";
export const GET_ROLE_LIST_ERROR = "GET_ROLE_LIST_ERROR";
export const SAVE_ROLE_START = "SAVE_ROLE_START";
export const SAVE_ROLE_SUCCESS = "SAVE_ROLE_SUCCESS";
export const SAVE_ROLE_ERROR = "SAVE_ROLE_ERROR";
export const GET_PERMISSION_LIST_START = "GET_PERMISSION_LIST_START";
export const GET_PERMISSION_LIST_SUCCESS = "GET_PERMISSION_LIST_SUCCESS";
export const GET_PERMISSION_LIST_ERROR = "GET_PERMISSION_LIST_ERROR";
