import OneBrand from "../assets/images/it-park-brand.svg";
import ProfiBrand from "../assets/images/Profi-School-brand.svg";

import OneBg from "../assets/images/login-bg-ONE.png";
import ProfiBg from "../assets/images/login-bg-PROFI.png";

export const IS_DEV = process.env.NODE_ENV !== "production";
// const DEV_URL = "http://95.130.227.45:8000";
// const PROD_URL = "http://api.oneschool.uz";
// const HOST_URL = window.location.hostname.includes("95.130.227.45")
// 	? DEV_URL
// 	: PROD_URL;
// export const baseUrl = IS_DEV ? DEV_URL : HOST_URL;

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const socketPort = process.env.REACT_APP_BASE_URL?.replace('api','chat');
export const chatPort = process.env.REACT_APP_BASE_URL?.replace('api','chat').replace('http','ws');

export const IS_TEST_SERVER = window.location.hostname.includes("it-project")
	? true
	: false;

/**
 * @type {"ONE" | "PROFI"}
 * @description Can be `ONE` | `PROFI`
 */
export const appType = process.env.REACT_APP_TYPE;

let logo = OneBrand;
let bg = OneBg;
let isOne = true;

if (appType == "PROFI") {
	logo = ProfiBrand;
	bg = ProfiBg;
	isOne = false;
}

export const baseLogo = logo;
export const baseBg = bg;
export const isOneschool = isOne
