import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../actions/loaderActions";
import CButton from "./CButton";
import CModal from "./CModal";

const enhancer = connect(
  ({ loader: { messages, loaders } }) => ({
    messages,
    loaders,
  }),
  { closeModal },
);

const Loader = ({ messages, loaders, ...props }) => {
  if (loaders) {
    return (
      <div className="loader-wrapper">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (messages.length) {
    setTimeout(() => {
      props.closeModal();
    }, 7000);
    return (
      <CModal
        size={"sm"}
        show={true}
        onHide={props.closeModal}
        footer={
          <div className="d-flex justify-content-end">
            <CButton onClick={props.closeModal}>Закрыть</CButton>
          </div>
        }
      >
        <div>{messages.join("\n")}</div>
      </CModal>
    );
  }

  return null;
};
export default enhancer(Loader);
