import { DateTime } from "luxon";

import { getQuarterDates } from "../utils/DateUtils";
import { isIncludesInRange } from "../utils/CommonUtils";

export function filterListByQuarter(list, { quarter, year }) {
  const quarterDates = getQuarterDates({ quarter, year });

  if (!quarterDates) {
    return list;
  }

  const toDateObject = quarterDates.to.toObject();
  const fromDateObject = quarterDates.from.toObject();

  return list.filter((x) => {
    const dateObject = DateTime.fromFormat(x.on, "yyyy-MM-dd").toObject();

    return (
      isIncludesInRange({
        to: toDateObject.year,
        value: dateObject.year,
        from: fromDateObject.year,
      }) &&
      isIncludesInRange({
        to: toDateObject.month,
        value: dateObject.month,
        from: fromDateObject.month,
      })
    );
  });
}

export function formatDayIndexToString(weekday) {
  switch (weekday) {
    case 1:
      return "MONDAY";
    case 2:
      return "TUESDAY";
    case 3:
      return "WEDNESDAY";
    case 4:
      return "THURSDAY";
    case 5:
      return "FRIDAY";
    case 6:
      return "SATURDAY";
    case 7:
      return "SUNDAY";
  }
}

export function formatWeekDayToIndex(weekday) {
  switch (weekday) {
    case "MONDAY":
      return 1;
    case "TUESDAY":
      return 2;
    case "WEDNESDAY":
      return 3;
    case "THURSDAY":
      return 4;
    case "FRIDAY":
      return 5;
    case "SATURDAY":
      return 6;
    case "SUNDAY":
      return 7;
  }
}

export function formatWeekDayToString(weekday) {
  switch (weekday) {
    case 1:
      return "Понедельник";
    case 2:
      return "Вторник";
    case 3:
      return "Среда";
    case 4:
      return "Четверг";
    case 5:
      return "Пятница";
    case 6:
      return "Суббота";
    case 7:
      return "Воскресенье";
  }
}

export function formatWeekDayToShortString(weekday) {
  switch (weekday) {
    case 1:
      return "Пн";
    case 2:
      return "Вт";
    case 3:
      return "Ср";
    case 4:
      return "Чт";
    case 5:
      return "Пт";
    case 6:
      return "Сб";
    case 7:
      return "Вс";
  }
}
