import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import store from "../store/store";
import Loader from "../components/Loader";
import RootContainer from "./RootContainer";
import { ThemeContainer } from "./ThemeContainer";
import { LoaderProvider } from "./LoaderContainer";

export function AppContainer() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnMount: false,
				refetchInterval: false,
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false,
			},
		},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<BrowserRouter>
					<Loader />
					<LoaderProvider>
						<ThemeContainer>
							<RootContainer />
						</ThemeContainer>
					</LoaderProvider>
				</BrowserRouter>
			</Provider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}
