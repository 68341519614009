import { CLEAR_TOKEN, SAVE_TOKEN, SET_ROLE, UPDATE_TOKEN } from "../actionTypes/authActionsTypes";

export function initApp() {
  const token = JSON.parse(localStorage.getItem("token"));
  const role = JSON.parse(localStorage.getItem("role"));

  return (dispatch) => {
    if (token && token.access) {
      dispatch({
        type: SET_ROLE,
        role,
      });
      dispatch({
        type: SAVE_TOKEN,
        token,
      });
      return true;
    } else {
      return false;
    }
  };
}

export function setRole(role) {
  return {
    type: SET_ROLE,
    role,
  };
}

export function saveToken(token) {
  return {
    type: SAVE_TOKEN,
    token,
  };
}

export function updateToken(token) {
  return {
    type: UPDATE_TOKEN,
    token,
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: CLEAR_TOKEN });
  };
}
