import React from "react";
import { Modal } from "react-bootstrap";

function CModal({
  show,
  onHide,
  title,
  footer,
  children,
  showCloseButton = true,
  titleClassName,
  headerClassName,
  bodyClassName,
  footerClassName,
  ...props
}) {
  return (
    <Modal show={show} onHide={onHide} {...props}>
      <Modal.Header className={headerClassName} closeButton={showCloseButton}>
        <Modal.Title className={titleClassName}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyClassName}>{children}</Modal.Body>
      <Modal.Footer className={footerClassName}>{footer}</Modal.Footer>
    </Modal>
  );
}

export default CModal;
