import React, { useEffect } from "react";

import CModal from "./CModal";
import CButton from "./CButton";

export function Message({ message, onHide, timeout = 7000 }) {
  useEffect(() => {
    const timer = setTimeout(() => onHide(message), timeout);

    return () => clearTimeout(timer);
  }, [message, onHide, timeout]);

  return (
    <CModal
      size="sm"
      show={true}
      onHide={() => onHide(message)}
      footer={
        <div className="d-flex justify-content-end">
          <CButton onClick={() => onHide(message)}>Закрыть</CButton>
        </div>
      }
    >
      <div>{message}</div>
    </CModal>
  );
}
