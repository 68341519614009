import createReducer from "../helpers/createReducer";
import {
  CLEAR_TOKEN,
  SAVE_LOGIN_FORM,
  SAVE_REGISTER_FORM,
  SAVE_TOKEN,
  SET_ROLE,
  UPDATE_TOKEN,
  VALIDATE_REFRESH_TOKEN,
} from "../actionTypes/authActionsTypes";

const defaultState = {
  token: {},
};
const reducers = {
  [SET_ROLE](state, { role }) {
    localStorage.setItem("role", JSON.stringify(role));
    return {
      ...state,
      role,
    };
  },
  [SAVE_LOGIN_FORM](state) {
    return {
      ...state,
    };
  },
  [SAVE_REGISTER_FORM](state) {
    return {
      ...state,
    };
  },
  [UPDATE_TOKEN](state, { token }) {
    return {
      ...state,
      token,
    };
  },
  [SAVE_TOKEN](state, { token }) {
    localStorage.setItem("token", JSON.stringify(token));
    return {
      ...state,
      token,
    };
  },
  [CLEAR_TOKEN](state) {
    localStorage.removeItem("token");
    return {
      ...state,
      token: {},
    };
  },
  [VALIDATE_REFRESH_TOKEN](state) {
    return {
      ...state,
    };
  },
};

export const authRoleSelector = ({ auth }) => auth.role;

export default createReducer(defaultState, reducers);
