import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/styles";

import { createTypography } from "../helpers/TypographyHelpers";
import createBreakpoints from "../helpers/BreakpointsHelpers";

const PALETTE = {
	primary: {
		main: "var(--primary_color)",
	},
	secondary: {
		main: "#ffc97c",
	},
	error: {
		main: "#d9534f",
		hover: "#bd423e",
	},
	success: {
		main: "#06bf69",
		background: "#f6fff5",
	},
	warning: {
		main: "#E87525",
	},
	danger: {
		main: "#ff4747",
	},
	text: {
		main: "#00264b",
		disabled: "#c1c1c1",
	},
	common: {
		black: "#000000",
		white: "#ffffff",
		transparent: "rgba(0, 0, 0, 0)",
	},
	assessment: {
		one: "#00264b",
		two: "#ff0000",
		three: "var(--primary_color)",
		four: "var(--primary_color)",
		five: "#06bf69",
	},
	border: {
		main: "#eff4fa",
		active: "#000000",
	},
	grey: {
		100: "#e6e6e6",
		200: "#adadad",
		300: "#c1c1c1",
		400: "#eff4fa",
		500: "#f7f7f7",
		600: "#fafafa",
	},
};

const TYPOGRAPHY = {};

const BREAKPOINTS = {};

const THEME = {
	palette: PALETTE,
	breakpoints: createBreakpoints(BREAKPOINTS),
	typography: createTypography(PALETTE, TYPOGRAPHY),
};

ThemeContainer.propTypes = {
	children: PropTypes.node,
};

export function ThemeContainer({ children }) {
	return <ThemeProvider theme={THEME}>{children}</ThemeProvider>;
}
