export const GET_ACTIVE_URL = "GET_ACTIVE_URL";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export function getActiveUrl(url) {
  return (dispatch) => {
    dispatch({ type: GET_ACTIVE_URL, url });
  };
}

export function setActiveTab(activeTab) {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_TAB, activeTab });
  };
}
