import createReducer from "../helpers/createReducer";
import { EMPTY_MESSAGES, SHOW_MESSAGE, START_LOADER, STOP_LOADER } from "../actions/loaderActions";

const defaultState = {
  loaders: 0,
  messages: [],
};

const reducers = {
  [START_LOADER]({ loaders, messages }) {
    return {
      loaders: loaders + 1,
      messages,
    };
  },
  [STOP_LOADER]({ loaders, messages }, { info }) {
    if (!info) {
      return {
        loaders: loaders > 0 ? loaders - 1 : 0,
        messages,
      };
    }
    return {
      loaders: loaders > 0 ? loaders - 1 : 0,
      messages: [...messages, info],
    };
  },
  [SHOW_MESSAGE]({ loaders, messages }, { info }) {
    return {
      loaders,
      messages: [...messages, info],
    };
  },
  [EMPTY_MESSAGES]() {
    return {
      loaders: 0,
      messages: [],
    };
  },
};

export default createReducer(defaultState, reducers);
