import createReducer from "../helpers/createReducer";
import {
  DELETE_ROLE,
  SET_DELETING_ROLE,
  SET_ADD_ROLE_MODAL_VISIBLE,
  DELETE_ROLE_MODAL_VISIBLE,
  SET_EDIT_MODE_ROLE,
  SET_EDIT_ROLE,
  GET_ROLE_LIST_SUCCESS,
  GET_PERMISSION_LIST_SUCCESS,
} from "../actionTypes/roleActionTypes";

const defaultState = {
  show: false,
  deleteModalVisible: false,
  item: {},
  roleList: [],
  permissionList: [],
};
const reducers = {
  [SET_ADD_ROLE_MODAL_VISIBLE](state) {
    return {
      ...state,
      show: !state.show,
    };
  },
  [GET_ROLE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      roleList: data.results,
    };
  },
  [GET_PERMISSION_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      permissionList: data.results,
    };
  },
  [SET_DELETING_ROLE](state, { item }) {
    return {
      ...state,
      item,
    };
  },
  [DELETE_ROLE](state) {
    const roles = state.roles.filter((item) => item.id !== state.item.id);
    return {
      ...state,
      roles,
      item: {},
      deleteModalVisible: false,
    };
  },
  [DELETE_ROLE_MODAL_VISIBLE](state) {
    return {
      ...state,
      deleteModalVisible: !state.deleteModalVisible,
    };
  },
  [SET_EDIT_MODE_ROLE](state, { data }) {
    return {
      ...state,
      item: data,
    };
  },
  [SET_EDIT_ROLE](state, { data }) {
    const res = state.roles.map((role) => {
      if (role.id === state.item.id) {
        role.name = data.name;
      }
      return role;
    });
    return {
      ...state,
      roles: res,
      item: {},
    };
  },
};
export default createReducer(defaultState, reducers);
