export const ADD_MODAL_VISIBLE = "ADD_MODAL_VISIBLE";
export const ADD_CLASS_MODAL_VISIBLE = "ADD_CLASS_MODAL_VISIBLE";
export const ADD_ROOM_MODAL_VISIBLE = "ADD_ROOM_MODAL_VISIBLE";

export const GET_CLASS_SUBJECTS_START = "GET_CLASS_SUBJECTS_START";
export const GET_CLASS_SUBJECTS_SUCCESS = "GET_CLASS_SUBJECTS_SUCCESS";
export const GET_CLASS_SUBJECTS_ERROR = "GET_CLASS_SUBJECTS_ERROR";

export const SAVE_CLASS_SUBJECTS_START = "SAVE_CLASS_SUBJECTS_START";
export const SAVE_CLASS_SUBJECTS_SUCCESS = "SAVE_CLASS_SUBJECTS_SUCCESS";
export const SAVE_CLASS_SUBJECTS_ERROR = "SAVE_CLASS_SUBJECTS_ERROR";

export const GET_CLASS_SUBJECT_START = "GET_CLASS_SUBJECT_START";
export const GET_CLASS_SUBJECT_SUCCESS = "GET_CLASS_SUBJECT_SUCCESS";
export const GET_CLASS_SUBJECT_ERROR = "GET_CLASS_SUBJECT_ERROR";

export const DELETE_CLASS_SUBJECT_START = "DELETE_CLASS_SUBJECT_START";
export const DELETE_CLASS_SUBJECT_SUCCESS = "DELETE_CLASS_SUBJECT_SUCCESS";
export const DELETE_CLASS_SUBJECT_ERROR = "DELETE_CLASS_SUBJECT_ERROR";

export const SAVE_LIST_CLASS_START = "SAVE_LIST_CLASS_START";
export const SAVE_LIST_CLASS_SUCCESS = "SAVE_LIST_CLASS_SUCCESS";
export const SAVE_LIST_CLASS_ERROR = "SAVE_LIST_CLASS_ERROR";

export const GET_LIST_CLASS_START = "GET_LIST_CLASS_START";
export const GET_LIST_CLASS_SUCCESS = "GET_LIST_CLASS_SUCCESS";
export const GET_LIST_CLASS_ERROR = "GET_LIST_CLASS_ERROR";

export const GET_LIST_CLASSES_START = "GET_LIST_CLASSES_START";
export const GET_LIST_CLASSES_SUCCESS = "GET_LIST_CLASSES_SUCCESS";
export const GET_LIST_CLASSES_ERROR = "GET_LIST_CLASSES_ERROR";

export const DELETE_LIST_CLASS_START = "DELETE_LIST_CLASS_START";
export const DELETE_LIST_CLASS_SUCCESS = "DELETE_LIST_CLASS_SUCCESS";
export const DELETE_LIST_CLASS_ERROR = "DELETE_LIST_CLASS_ERROR";

export const GET_ROOM_SUBJECTS_SUCCESS = "GET_ROOM_SUBJECTS_SUCCESS";
export const GET_ROOM_SUBJECTS_START = "GET_ROOM_SUBJECTS_START";
export const GET_ROOM_SUBJECTS_ERROR = "GET_ROOM_SUBJECTS_ERROR";

export const SAVE_NEW_ROOM = "SAVE_NEW_ROOM";

export const SAVE_ROOM_SUBJECTS_START = "SAVE_ROOM_SUBJECTS_START";
export const SAVE_ROOM_SUBJECTS_SUCCESS = "SAVE_ROOM_SUBJECTS_SUCCESS";
export const SAVE_ROOM_SUBJECTS_ERROR = "SAVE_ROOM_SUBJECTS_ERROR";

export const GET_ROOM_SUBJECT_START = "GET_ROOM_SUBJECT_START";
export const GET_ROOM_SUBJECT_SUCCESS = "GET_ROOM_SUBJECT_SUCCESS";
export const GET_ROOM_SUBJECT_ERROR = "GET_ROOM_SUBJECT_ERROR";

export const DELETE_ROOM_SUBJECT_START = "DELETE_ROOM_SUBJECT_START";
export const DELETE_ROOM_SUBJECT_SUCCESS = "DELETE_ROOM_SUBJECT_SUCCESS";
export const DELETE_ROOM_SUBJECT_ERROR = "DELETE_ROOM_SUBJECT_ERROR";

export const SET_FORM_FIELD_VALUE = "SET_FORM_FIELD_VALUE";
