import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { IS_DEV } from "../constants/AppConstants";

import reducers from "./reducers";
import apiMiddleware from "../middlewares/apiMiddleware";

export default createStore(
  combineReducers({
    ...reducers,
  }),
  applyMiddleware(...[apiMiddleware, thunkMiddleware, IS_DEV && logger].filter(Boolean)),
);
