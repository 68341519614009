import axios from "axios";
import {
	assign,
	camelCase,
	defaults,
	get,
	isArray,
	isFinite,
	isString,
	toPlainObject,
} from "lodash";
import store from "../store/store";
import { updateToken } from "../actions/authActions";
import { DefaultData } from "../api/authApi";
import { baseUrl as URL } from "../constants/AppConstants";

export const baseUrl = URL;
export const defaultHeaders = {
	"Content-Type": "application/json",
};

export class AppApi {
	refreshToken(refresh) {
		return new Promise((resolve, reject) => {
			axios
				.request({
					baseURL: baseUrl,
					// url: GET_TOKEN_URL,
					params: assign(
						{},
						{
							refresh,
							grant_type: "refresh_token",
							client_id: DefaultData.client_id,
						}
					),
					method: "POST",
				})
				.then(({ data }) => {
					// here should be parsed the token
					store.dispatch(
						updateToken({
							...data,
							expires_in: Date.now() + data.expires_in * 1000,
						})
					);

					resolve({ data });
				})
				.catch((error) => reject(error));
		});
	}

	async checkToken() {
		// const state = store.getState();

		const { access, refresh, expires_in } =
			JSON.parse(localStorage.getItem("token")) || {};

		if (expires_in * 1000 < Date.now() && refresh) {
			const { data } = await this.refreshToken(refresh);

			return data.access;
		}

		return Boolean(access) && access !== "null" ? access : undefined;
	}

	get(url, options) {
		return this.request(assign({ url, method: "GET" }, options));
	}
	post(url, options) {
		return this.request(assign({ url, method: "POST" }, options));
	}

	put(url, options) {
		return this.request(assign({ url, method: "PUT" }, options));
	}

	delete(url, options) {
		return this.request(assign({ url, method: "DELETE" }, options));
	}

	async request(options) {
		const request = defaults(options, {
			token: await this.checkToken(),
			/* eslint-disable-next-line */
			baseURL: baseUrl,
		});

		const headers = assign(
			{},
			defaultHeaders,
			toPlainObject(request.headers)
		);

		const config = {
			headers,

			method: request.method,

			timeout: 300000,
			url: request.url,
			baseURL: request.baseURL,

			data: request.data,
			params: request.params,
		};

		if (Boolean(request.token) && !isString(config.headers.Authorization)) {
			config.headers.Authorization = `Bearer ${request.token}`;
		}

		if (isFinite(request.timeout)) {
			config.timeout = request.timeout;
		} else if (request.method === "GET") {
			config.timeout = 10 * 60 * 1000;
		}

		return axios.request(config).catch((error) => {
			const errorType = get(error, "response.data.error");
			const state = store.getState();

			const { refresh_token } = get(state, "auth.token", {});

			if (errorType === "invalid_token") {
				this.refreshToken(refresh_token).then(({ data }) => {
					const token = get(data, "token");

					if (token) {
						this.request({
							...config,
							headers: {
								...config.headers,
								Authorization: `Bearer ${token.access}`,
							},
						});
					}
				});

				throw new Error("invalid_token");
			}
			if (errorType === "unauthorized") {
				throw new Error(
					get(error, "response.data.error_description") ||
						"Wrong password"
				);
			}

			if (error?.response?.status === 400) {
				const { data } = error?.response;
				let appError = new Error();

				if (data?.data?.errors) {
					appError.formErrors = Object.keys(data.data.errors).reduce(
						(acc, item) => {
							if (item === "non_field_errors") {
								appError.message =
									data.data.errors[item].join("\n");
								return acc;
							}

							if (isArray(data.data.errors[item])) {
								return {
									...acc,
									[camelCase(item)]:
										data.data.errors[item].join(", "),
								};
							}

							if (isString(data.data.errors[item])) {
								return {
									...acc,
									[camelCase(item)]: data.data.errors[item],
								};
							}

							return acc;
						},
						{}
					);
				}

				throw appError;
			}

			return Promise.reject(error);
		});
	}
}
