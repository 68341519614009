import auth from "../reducers/authReducer";
import router from "../reducers/routerReducer";
import role from "../reducers/roleReducer";
import session from "../reducers/sessionReducer";
import student from "../reducers/studentReducer";
import lesson from "../reducers/lessonReducer";
import loader from "../reducers/loaderReducer";
import stuff from "../reducers/stuffReducer";
import list from "../reducers/listReducer.js";
import dashboard from "../reducers/dashboardReducer.js";
import currentLesson from '../reducers/currentLessonPathReducer'

const reducers = {
  auth,
  router,
  role,
  stuff,
  student,
  session,
  lesson,
  loader,
  list,
  dashboard,
  currentLesson
};

export default reducers;
