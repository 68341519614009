import { useDispatch } from "react-redux";
import React, { Suspense, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import Routes from "../constants/Routes";
import { initApp } from "../actions/authActions";
import {getFormData, getParentForm} from "../actions/sessionActions";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { authRoleSelector } from "../reducers/authReducer";
import { initLesson } from "../actions/currentLessonPathAction";
import { appType } from "../constants/AppConstants";
import { addFavicons } from "../utils/addFavicons";

const Login = React.lazy(() => import("../views/auth/Login"));
const TeacherContainer = React.lazy(() => import("./TeacherContainer"));
const DashboardContainer = React.lazy(() => import("./DashboardContainer"));
const ContactContainer = React.lazy(() => import("./ContactContainer"));
const StudentContainer = React.lazy(() => import("./StudentContainer"));

function RootContainer() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const role = useShallowEqualSelector(authRoleSelector);

	useEffect(() => {
		const htmlClassList = document.body.classList;
		if (appType == "PROFI") {
			htmlClassList.add("profi-theme");
			localStorage.setItem('appType', appType)
		} else if (appType == "ONE") {
			htmlClassList.add("one-theme");
			localStorage.setItem('appType', appType)
		}
		// addFavicons();
	}, []);

	useEffect(() => {
		const result = dispatch(initApp());
		dispatch(initLesson());

		if (!result) {
			history.replace(Routes.Login);
		} else if (location.pathname === "/") {
			switch (role) {
				case "ADMIN":
					// history.push(Routes.Home);
					dispatch(getFormData());
					history.replace(Routes.Dashboard);
					break;
				case "TEACHER":
					// history.push(Routes.Home);
					dispatch(getFormData());
					history.replace(Routes.Teacher);
					break;
				case "CONTACT":
					history.replace(Routes.Contact);
					break;
				case "STUDENT":
					history.replace(Routes.Student);
					break;
			}
		} else {
			if (role === "ADMIN" || role === "TEACHER") {
				dispatch(getFormData());
			}
		}
	}, [dispatch, history, location.pathname, role]);

	return (
		<Suspense fallback={<span />}>
			<Switch>
				<Route path={Routes.Login} exact={true} component={Login} />

				<Route path={Routes.Dashboard} component={DashboardContainer} />

				<Route path={Routes.Teacher} component={TeacherContainer} />

				<Route path={Routes.Contact} component={ContactContainer} />

				<Route path={Routes.Student} component={StudentContainer} />
			</Switch>
		</Suspense>
	);
}

export default RootContainer;
